var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-container',[_c('h5',{staticClass:"text-h5"},[_vm._v("Compatibility Matrix")]),_c('v-divider',{staticClass:"my-1"}),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.projects,"outlined":"","dense":"","label":"Provider","color":"black"},on:{"change":_vm.getProviderAnalysis}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.providerVersions,"outlined":"","dense":"","label":"ProviderVersion","color":"black"},on:{"change":_vm.setProviderVersion}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.projects,"outlined":"","dense":"","label":"Consumer","color":"black"},on:{"change":_vm.getConsumerAnalysis}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.consumerVersions,"outlined":"","dense":"","label":"ConsumerVersion","color":"black"},on:{"change":_vm.setConsumerVersion}})],1)],1),(_vm.provider && _vm.consumer)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.compatibilities},scopedSlots:_vm._u([{key:"item.providerVersion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.providerVersion)+" "),(
            _vm.projectEnvs &&
            _vm.projectEnvs[item.provider] &&
            _vm.projectEnvs[item.provider][item.providerVersion]
          )?_c('span',_vm._l((_vm.projectEnvs[item.provider][item.providerVersion]),function(env){return _c('v-chip',{key:env,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(env))])}),1):_vm._e()]}},{key:"item.consumerVersion",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.consumerVersion)+" "),(
            _vm.projectEnvs &&
            _vm.projectEnvs[item.consumer] &&
            _vm.projectEnvs[item.consumer][item.consumerVersion]
          )?_c('span',_vm._l((_vm.projectEnvs[item.consumer][item.consumerVersion]),function(env){return _c('v-chip',{key:env,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(env))])}),1):_vm._e()]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status === 'PASSED')?_c('div',[_c('v-btn',{staticClass:"green--text",attrs:{"text":"","icon":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check-bold ")])],1)],1):_c('div',[_c('v-btn',{staticClass:"red--text",attrs:{"text":"","icon":""},on:{"click":function($event){("" + (_vm.openDialog(item)))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close-thick ")])],1)],1)]}},{key:"item.verifiedAt",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getRelativeDate(item.verifiedAt))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(new Date(item.verifiedAt)))])])]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"width":"1000","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_vm._v(" Compatibility Failures ")]),_c('v-card-text',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle-outline")])],1),_vm._l((_vm.selectedItem.exceptions),function(exception){return _c('v-list-item-content',{key:exception._id},[_c('v-list-item-title',[_vm._v(_vm._s(exception.flow))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(exception.error)+" ")])],1)})],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }